<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="AlignJustifyIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Depenses</span>
            </div>
            <vs-divider />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table v-model="selected" pagination max-items="10" search data="">
              <template slot="header">
                <!-- ACTION - DROPDOWN -->
                <vs-dropdown vs-trigger-click class="cursor-pointer">

                  <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                    <span class="mr-2 leading-none">Actions</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                  </div>

                  <vs-dropdown-menu>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Supprimer</span>
                      </span>
                    </vs-dropdown-item>
                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>Print</span>
                      </span>
                    </vs-dropdown-item>

                    <vs-dropdown-item>
                      <span class="flex items-center">
                        <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                        <span>CSV</span>
                      </span>
                    </vs-dropdown-item>

                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-button class="mb-4 ml-5 md:mb-0" @click="$router.push('/apps/depense/depense-add')">Nouvelle dépense</vs-button>
              </template>
              <template slot="thead">
                <vs-th sort-key="numero">
                  N°
                </vs-th>
                <vs-th sort-key="IdOfficine">
                  Officine
                </vs-th>
                <vs-th sort-key="DateOp">
                  DateOp
                </vs-th>
                <vs-th sort-key="HeurOp">
                  HeurOp
                </vs-th>
                <vs-th sort-key="Beneficiaire">
                  Beneficiaire
                </vs-th>
                <vs-th sort-key="Montant">
                  Montant
                </vs-th>
                <vs-th sort-key="Motif">
                  Motif
                </vs-th>
                <vs-th sort-key="IdUser">
                  IdUser
                </vs-th>
                <vs-th sort-key="Encaiss_Vrai">
                  Nbr 500
                </vs-th>
                <vs-th sort-key="MoyPaiement">
                  Nbr 200
                </vs-th>
                <vs-th sort-key="Numero">
                  Nbr 100
                </vs-th>
                <vs-th sort-key="NumCheque">
                  En Caisse
                </vs-th>

              </template>

              <template slot-scope="{data}">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
                  <vs-td :data="data[indextr]">
                    {{indextr + 1}}
                  </vs-td>

                  <vs-td :data="data[indextr].IdOfficine">
                    {{data[indextr].IdOfficine}}
                  </vs-td>
                  <vs-td :data="data[indextr].DateOp">
                    {{data[indextr].DateOp}}
                  </vs-td>
                  <vs-td :data="data[indextr].HeurOp">
                    {{data[indextr].HeurOp}}
                  </vs-td>
                  <vs-td :data="data[indextr].Beneficiaire">
                    {{data[indextr].Beneficiaire}}
                  </vs-td>

                  <vs-td :data="data[indextr].Montant">
                    {{data[indextr].Montant}}
                  </vs-td>
                  <vs-td :data="data[indextr].Motif">
                    {{data[indextr].Motif}}
                  </vs-td>

                  <vs-td :data="data[indextr].IdUser">
                    {{data[indextr].IdUser}}
                  </vs-td>
                  <vs-td :data="data[indextr].Encaiss_Vrai">
                    {{data[indextr].Encaiss_Vrai}}
                  </vs-td>

                  <vs-td :data="data[indextr].MoyPaiement">
                    {{data[indextr].MoyPaiement}}
                  </vs-td>
                  <vs-td :data="data[indextr].Numero">
                    {{data[indextr].Numero}}
                  </vs-td>

                  <vs-td :data="data[indextr].NumCheque">
                    {{data[indextr].NumCheque}}
                  </vs-td>

                  <vs-td :data="data[indextr]._id">
                    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
                      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateDepenseShowForm(data[indextr])" />
                      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selected:[],
      depenses:[],
      depenseId: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.libelle !== ''

    }

  },

  methods: {
    updateDepenseShowForm (data) {

      // eslint-disable-next-line no-unused-expressions
      this.$router.push(`/apps/depense/depense-edit/${data._id}`).catch((err) => { 'erreur vers page edit >', err })
    },
    confirmDeleteRecord (data) {

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `You are about to delete "${data._id}"`,
        accept: this.deleteRecord,
        acceptText: 'Delete'
      })
    },
    deleteRecord () {

      // alert('supprimé !')
    //   this.$store.dispatch('moduleDepense/removeDepense', this.params.data._id)
      // .then((res) => {
      //   console.log('success >> ', res)
      //   this.showDeleteSuccess()
      // })
      // .catch(err => { console.error(err)       })

      this.showDeleteSuccess()
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'User Deleted',
        text: 'The selected depense was successfully deleted'
      })
    }
  },
  created () {
  }
}
</script>

